// 保护展示与环境整治工程记录
const rightTableData = {
    snTable: {
        table: {
            data: [],
            expandRowKeys: [],
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'name',
                label: '文档类型',
            }, {
                prop: 'wdmc',
                label: '附件',
                transList: {
                    '待上传': '待上传',
                    '查看': '查看',
                    '无': '无',
                },
                colorList: {
                    '待上传': '#606266',
                    '查看': '#28ccd9',
                    '无': '#606266',
                },
            }, {
                prop: 'shzt',
                label: '数据状态',
                transList: {
                    0: '未审核', // 未审核 / 提交未审核
                    1: '未提交', // 未提交
                    2: '审核通过', // 审核通过
                    3: '审核不通过', // 审核不通过
                },
                colorList: {
                    0: '#28ccd9',
                    1: '#ff9066',
                    2: '#2ec392',
                    3: '#fa6f1b'
                },
                minWidth: 90
            }],
            // 操作列
            columnsBtn: {
                width: 70,
                buttonData: [{
                    isShow: true,
                    showMethod: 'showSeeBtn',
                    btnType: 'icon',
                    operateType: 'columnSee24',
                    title: '查看',
                    icon: 'el-icon-view',
                    backColor: 'transparent',
                    color: '#20a0ff'
                }, {
                    isShow: true,
                    showMethod: 'showEditBtn',
                    btnType: 'icon',
                    operateType: 'columnEdit24',
                    title: '编辑',
                    icon: 'el-icon-edit',
                    backColor: 'transparent',
                    color: 'green'
                }, {
                    isShow: true,
                    showMethod: 'showCheckBtn',
                    btnType: 'icon',
                    operateType: 'columnCheck24',
                    title: '审核',
                    icon: 'el-icon-document-checked',
                    backColor: 'transparent',
                    color: '#00a7bd'
                }, {
                    isShow: false, // 都不显示删除按钮
                    btnType: 'icon',
                    operateType: 'columnItemDelete',
                    title: '删除',
                    icon: 'el-icon-delete',
                    backColor: 'transparent',
                    color: 'red'
                }]
            }
        },
    }
};
export { rightTableData };