const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
            treeProps: {
                children: 'Children',
                hasChildren: 'hasChildren'
            }
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                // {
                //     isShow: true,
                //     type: 'selection',
                //     width: 35
                // }, 
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                }
            ],
            // 数据列
            columnsData: [],
            // 操作列
            columnsBtn: {
                width: 110,
                buttonData: [
                    {
                        isShow: true,
                        showMethod: 'showRefreshBtn',
                        btnType: 'icon',
                        operateType: 'columnRefresh',
                        title: '版本变更',
                        icon: 'el-icon-refresh',
                        backColor: 'transparent',
                        color: 'blue'
                    },
                    {
                        isShow: true,
                        showMethod: 'showAddBtn',
                        btnType: 'icon',
                        operateType: 'columnAdd',
                        title: '新增子节点',
                        icon: 'el-icon-circle-plus-outline',
                        backColor: 'transparent',
                        color: 'blue'
                    },
                    {
                        isShow: true,
                        showMethod: 'showSeeBtn',
                        btnType: 'icon',
                        operateType: 'columnSee',
                        title: '查看',
                        icon: 'el-icon-view',
                        backColor: 'transparent',
                        color: '#20a0ff'
                    }, {
                        isShow: true,
                        showMethod: 'showEditBtnAdmin',
                        btnType: 'icon',
                        operateType: 'columnEditAdmin',
                        title: '编辑',
                        icon: 'el-icon-edit',
                        backColor: 'transparent',
                        color: 'green'
                    }, {
                        isShow: true,
                        showMethod: 'showEditBtn',
                        btnType: 'icon',
                        operateType: 'columnEdit',
                        title: '编辑',
                        icon: 'el-icon-edit',
                        backColor: 'transparent',
                        color: 'green'
                    }, {
                        isShow: true,
                        showMethod: 'showCheckBtn',
                        btnType: 'icon',
                        operateType: 'columnCheck',
                        title: '审核',
                        icon: 'el-icon-document-checked',
                        backColor: 'transparent',
                        color: '#00a7bd'
                    }, {
                        isShow: true,
                        showMethod: 'showDelBtn',
                        btnType: 'icon',
                        operateType: 'columnDelete',
                        title: '删除',
                        icon: 'el-icon-delete',
                        backColor: 'transparent',
                        color: 'red'
                    }, {
                        isShow: true,
                        showMethod: 'columnIntegrat',
                        btnType: 'icon',
                        operateType: 'columnIntegrat',
                        title: '对接',
                        icon: 'el-icon-upload',
                        backColor: 'transparent',
                        color: '#008c8c'
                    }

                ]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true
        }],
        selectData: [{
            label: '数据状态',
            clearable: true,
            placeholder: "请选择数据状态",
            list: [{
                ID: '0',
                NAME: '未审核'
            }, {
                ID: '1',
                NAME: '未提交'
            }, {
                ID: '2',
                NAME: '审核通过'
            }, {
                ID: '3',
                NAME: '审核不通过'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'SHZT',
            operateType: 'search',
            isShow: true
        }],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    },
    snButton: {
        buttonData: [
            //     {
            //     isShow: true,
            //     btnType: 'button',
            //     operateType: 'buttonDel',
            //     name: '批量删除(测试用)',
            //     round: true,
            //     backColor: '#fa6f1b',
            //     // color: '#fff'
            // },
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                // beforeIcon: 'el-icon-edit',
                // afterIcon: 'el-icon-delete',
                backColor: '#28ccd9',
                // color: '#fff'
            }, {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export { commonData };