// 有刷新按钮的页面
let list = [
    {
        name: "遗产总图编辑页面",
        itemID: "5"
    },
    {
        name: "遗产要素分布图编辑页面",
        itemID: "6"
    },
    {
        name: "遗产地和各级文保单位的对应关系编辑页面",
        itemID: "8"
    },
    {
        name: "病害分布图编辑页面",
        itemID: "11"
    },
    {
        name: "遗产要素单体或局部的测绘图编辑页面",
        itemID: "13"
    },
    {
        name: "遗产要素单体或局部照片编辑页面",
        itemID: "14"
    },
    {
        name: "遗产地管理责任机构信息编辑页面",
        itemID: "17"
    },
    {
        name: "监测机构编辑页面",
        itemID: "18"
    },
    {
        name: "保护区划图编辑页面",
        itemID: "20"
    },
    {
        name: "突出普遍价值（SOUV）声明材料",
        itemID: "21"
    },
]

let listNew = []
list.map((item) => {
    listNew.push(item.itemID)
})
export default listNew
