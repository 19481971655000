// 在基础数据里 不需要对接到总平台(三大类32项不包括的)
// 学术研究里 只有著作方式 为：专著的才显示 对接到总平台
let list = [
    {
        name: "申遗文本",
        itemID: "1"
    },
    {
        name: "大会决议",
        itemID: "2"
    },
    {
        name: "申遗承诺",
        itemID: "33"
    },
    {
        name: "突出普遍价值（SOUV）声明材料",
        itemID: "3"
    },
]

let listNew = []
list.map((item) => {
    listNew.push(item.itemID)
})
export default listNew
