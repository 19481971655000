import connectZptNo from "./connect_zpt_no";
import refreshBtn from "./refresh_btn";
// SHZT 0 提交未审核/已提交  1 未提交  2 审核通过  3 审核不通过
const commonMethods = {
    // 0 可查看  1 可编辑  2 可审核
    isPower(type) {
        let powerList = localStorage.basicPower.split(',');
        return powerList.indexOf(type) > -1;
    },
    // 显示新建按钮 
    showNewBtn() {
        let inPower = this.isPower('1');
        return inPower;
    },
    // 显示导出按钮
    showExportBtn(row) {
        let inPower = this.isPower('3');
        return inPower;
    },
    // 显示查看按钮
    showSeeBtn(row) {
        let isEdit = this.isPower('1');
        let isCheck = this.isPower('2');
        if (Number(row.SHZT) === 2 || Number(row.SHZT) === 0 &&
            !isCheck || Number(row.SHZT) !== 0 &&
            !isEdit) {
            return true;
        } else {
            return false;
        }
    },
    // 显示编辑按钮 非管理员
    showEditBtn(row) {
        let inPower = this.isPower('1');
        // 24 保护展示与环境整治工程记录 超级管理员 
        if (row.LBMC === '工程项目' && !localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY)) {
            if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 2 || Number(row.SHZT) === 3) && inPower) {
                return true;
            } else {
                return false;
            }
        } else {
            if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 3) && inPower) {
                return true;
            } else {
                return false;
            }
        }
    },
    // 显示编辑按钮 管理员 只保存数据 不修改数据状态
    showEditBtnAdmin(row) {
        if (localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY) && (row.SHZT == '0' || row.SHZT == '2')) {
            return true
        } else {
            return false
        }
    },
    // 显示审核按钮
    showCheckBtn(row) {
        let inPower = this.isPower('2');
        if (row.SHZT !== '' && Number(row.SHZT) === 0 && inPower) {
            return true;
        } else {
            return false;
        }
    },
    // 显示删除按钮  超级管理员 无视数据状态
    showDelBtn(row) {
        if (localStorage.roleId.split(",").includes(window.ROLE_ID.CJGLY)) {
            return true
        }
        let inPower = this.isPower('1');
        if (row.LBMC === '工程项目') {
            if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 2 || Number(row.SHZT) === 3) && inPower) {
                return true;
            } else {
                return false;
            }
        } else {
            if ((Number(row.SHZT) === 1 || Number(row.SHZT) === 3) && inPower) {
                return true;
            } else {
                return false;
            }
        }
    },
    // 显示添加子节点的按钮（遗产要素清单）第三级以后不能再添加
    showAddBtn(row, vm) {
        let state = false;
        const ItemID = vm.$route.params.ItemID;
        let inPower = this.isPower('1');
        if (ItemID == 7 &&
            Number(row.SHZT) === 2 &&
            String(row.YCYSBM).length <= 21 &&
            inPower) {
            state = true;
        }
        return state
    },
    // 显示版本变更的按钮
    showRefreshBtn(row, vm) {
        let state = false
        const ItemID = vm.$route.params.ItemID;
        let inPower = this.isPower('1');
        if (refreshBtn.includes(ItemID) &&
            Number(row.SHZT) === 2 &&
            inPower && row.SFXBB === 'TRUE') {
            state = true;
        }
        return state
    },
    showConnect(row, vm) {
        // 学术研究里 只有著作方式 为：专著的才显示 对接到总平台
        let state = false
        let ItemID = vm.$route.params.ItemID;
        if (row.SHZT === "2" && row.SFYDJ == 0) {
            if (ItemID == 34 && row.ZZFS == "专著") {
                state = true;
            } else if (!connectZptNo.includes(ItemID)) {
                state = true
            }
        }
        return state;
    },
    // spanMethod({ row, column, rowIndex, columnIndex }) {
    // if (Object.keys(row).length === 1) {
    //     if (columnIndex === 0) {
    //         return [1, 4];
    //     }
    // }
    // }
};
export { commonMethods };