<template>
    <div id="basic-table" class="mainbox">
        <sn-bread
            :itemName="itemName"
            :breadlist="breadlist"
            v-if="system == 'system1'"
        ></sn-bread>
        <sn-bread
            :itemName="itemName"
            :breadlist="breadlist2"
            v-if="system == 'system2'"
        ></sn-bread>
        <sn-bread
            :itemName="itemName"
            :breadlist="breadlist3"
            v-if="system == 'system3'"
        ></sn-bread>
        <div class="tableBox">
            <sn-table-group
                ref="tableGroup"
                :class="{ showRightTable: currentItem === '24' }"
                :tableGroupAttributes="tableGroupAttributes"
                :commonMethods.sync="commonMethods"
                @handleChange="handleChange"
            >
                <div
                    v-if="currentItem === '24'"
                    slot="tableRight"
                    class="tableRight sn_tablebody"
                >
                    <sn-table
                        :snTable.sync="rightTableData.snTable"
                        :commonMethods="commonMethods"
                        :boxHeight.sync="boxHeight"
                        @handleChange="handleChange"
                    >
                    </sn-table>
                </div>
            </sn-table-group>
            <sn-dialog :dialogData.sync="dialogData">
                <div class="table sn_tablebody">
                    <!-- <sn-table
                        :snTable.sync="fileData"
                        @handleChange="handleChange"
                    ></sn-table> -->
                    <SnFileDialog :fileData="fileData"> </SnFileDialog>
                </div>
            </sn-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { commonData } from "./table_config/sn-table-group-common";
import { commonMethods } from "./table_config/table-common-methods";
import { rightTableData } from "./table_config/table_group/sn-table-24";
import connectZptNo from "./table_config/connect_zpt_no";
export default {
    name: "application-text",
    props: {
        itemName: {
            type: String,
            default: function () {
                return "";
            },
        },
        currentChild: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            commonData,
            commonMethods,
            tableGroupAttributes: {},
            rightTableData,
            boxHeight: 0,
            currentItem: 1,
            currentMC: "",
            total: 20, // 每页的条数
            keyword: "",
            status: "",
            multipleSelection: [],
            sort: "",
            order: "",
            currentRow: {},
            dialogData: {
                title: "附件列表",
                dialog: false,
            },
            breadlist: [
                {
                    path: "",
                    name: "工作台面",
                },
                {
                    path: "",
                    name: "基础数据",
                },
            ],
            breadlist2: [
                {
                    path: "",
                    name: "工作台面",
                },
                {
                    path: "",
                    name: "数据维护",
                },
            ],
            breadlist3: [
                {
                    path: "",
                    name: "数据管理",
                },
            ],
            searchData: [],
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "CCLJ",
                    name: "WDMC",
                    list: [],
                },
                table: {
                    data: [],
                    showHeader: false,
                },
                columns: {
                    // 选择、序号
                    columnsOperate: [
                        {
                            isShow: true,
                            type: "selection",
                            width: 35,
                        },
                    ],
                    // 数据列
                    columnsData: [
                        {
                            prop: "SYTLJ",
                            label: "示意图",
                            width: 60,
                            showImg: true,
                        },
                        {
                            prop: "CCLJ",
                            label: "附件",
                            width: 90,
                            showFileBtn: true,
                            operateType: "dialogFileBtn",
                        },
                        {
                            prop: "WDMC",
                            label: "基础数据名称",
                        },
                    ],
                },
            },
        };
    },
    computed: {
        ...mapGetters(["heritageId", "system"]),
    },
    watch: {
        currentChild: {
            immediate: false,
            handler() {
                localStorage.removeItem("snSearch");
                localStorage.removeItem("snPage");
                if (Object.keys(this.currentChild).length === 0) return false;
                this.changeModule(this.currentChild);
            },
        },
    },
    created() {},
    mounted() {
        if (Object.keys(this.currentChild).length === 0) return false;
        this.changeModule(this.currentChild);
    },
    methods: {
        ...mapActions([
            "getBasicList",
            "deleteBasicData",
            "DockBasicData",
            "recalculateDatacode",
        ]),
        handleChange(type, value) {
            // this.$route
            console.log("type", type, "value", value);
            if (type.indexOf("column") > -1) {
                // 操作列的按钮
                this.handleColumnChange(type, value);
            } else if (type.indexOf("button") > -1) {
                // 其他按钮
                this.handleButtonChange(type, value);
            } else {
                switch (type) {
                    case "load": // 展开子节点(展开再去调服务)
                        this.getRowChildData(value);
                        break;
                    case "total": // 每页条数
                        this[value[1]] = value[0];
                        this.tableGroupAttributes.snPage.tiaoshu = value[0];
                        this.tableGroupAttributes.snPage.currentPage = 1;
                        // 将页码信息 放入到 localStorage
                        localStorage.setItem(
                            "snPage",
                            JSON.stringify(this.tableGroupAttributes.snPage)
                        );
                        this.getTableData();
                        break;
                    case "page": // 当前是第几页
                        this.tableGroupAttributes.snPage.currentPage = value[0];
                        // 将页码信息 放入到 localStorage
                        localStorage.setItem(
                            "snPage",
                            JSON.stringify(this.tableGroupAttributes.snPage)
                        );
                        this.getTableData();
                        break;
                    case "search": // 修改搜索条件(搜索及按钮)
                        this[value[1]] = value[0];
                        this.searchData.forEach((item) => {
                            if (item.type === value[1]) {
                                item.value = value[0];
                            }
                        });
                        // 关键字
                        if (value[1] == "keyword") {
                            this.tableGroupAttributes.snSearch.inputData[0].default =
                                value[0];
                        }
                        // select 框
                        this.tableGroupAttributes.snSearch.selectData.map(
                            (item) => {
                                if (item.value === value[1]) {
                                    item.default = value[0];
                                }
                            }
                        );
                        // 将搜索信息 放入到 localStorage
                        localStorage.setItem(
                            "snSearch",
                            JSON.stringify(this.tableGroupAttributes.snSearch)
                        );
                        this.tableGroupAttributes.snPage.currentPage = 1;
                        // 将页码信息 放入到 localStorage
                        localStorage.setItem(
                            "snPage",
                            JSON.stringify(this.tableGroupAttributes.snPage)
                        );
                        this.getTableData(); // 是否需要点击查询按钮才进行搜索
                        break;
                    case "searchBtn": // 点击查询按钮
                        this.getTableData();
                        break;
                    case "selection": // 选择左侧复选框
                        this.selectData(value[0]);
                        break;
                    case "fileBtn": // 点击表格的附件
                        this.getFileList(value);
                        break;
                    case "dialogFileBtn": // 点击弹出框的附件
                        this.openFile(value);
                        break;
                    case "rowClick": // 点击表格行
                        this.getRightTable(value[0]);
                        break;
                    case "textClick": // 点击表格单元格
                        this.getFileTable(value[0]);
                        break;
                    case "sortable": // 排序
                        this.sortableData(value[0]);
                        break;
                    case "showNotPassReason":
                        this.showNotPassReason(value[0].SHBTGSM);
                        break;
                    default:
                        break;
                }
            }
        },
        showNotPassReason(text) {
            this.$alert(text, "审核未通过原因", {
                confirmButtonText: "确定",
            });
        },
        handleColumnChange(type, value) {
            switch (type) {
                case "columnEditAdmin": // 超级管理员 编辑
                    this.toNewPage(value[0], "999", false, false);
                    break;
                case "columnEdit": // 编辑
                    this.toNewPage(value[0], "1", false, false);
                    break;
                case "columnEdit24": // 针对24右侧表格的编辑
                    this.toNewPage(
                        this.currentRow,
                        "1",
                        false,
                        false,
                        true,
                        value[0].name
                    );
                    break;
                case "columnAdd": // 增加子要素
                    this.toNewPage(value[0], "0", false, true);
                    break;
                case "columnRefresh": // 版本变更
                    this.toNewPage(value[0], "1", true, false);
                    break;
                case "columnSee": // 查看
                    this.toNewPage(value[0], "2", false, false);
                    break;
                case "columnSee24": // 针对24右侧表格的查看
                    this.toNewPage(
                        this.currentRow,
                        "2",
                        false,
                        false,
                        true,
                        value[0].name
                    );
                    break;
                case "columnCheck": // 审核
                    this.toNewPage(value[0], "3", false, false);
                    break;
                case "columnCheck24": // 针对24右侧表格的审核
                    this.toNewPage(
                        this.currentRow,
                        "3",
                        false,
                        false,
                        true,
                        value[0].name
                    );
                    break;
                case "columnDelete": // 删除
                    this.deleteData(value[0].ID, value[0].LXMC);
                    break;
                case "columnConnect":
                    this.connectToZptTest(value[0]);
                    break;
                default:
                    break;
            }
        },
        handleButtonChange(type, value) {
            console.log(type, value);
            switch (type) {
                case "buttonNew": // 新建
                    this.toNewPage("", "0", false, false);
                    break;
                case "buttonDel": // 批量删除
                    this.Deletesome();
                    break;
                case "buttonExport": // 导出
                    this.exportData();
                    break;
                default:
                    break;
            }
        },
        // 对接到总平台
        connectToZptTest(value) {
            this.$confirm("对接到总平台, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.connectToZpt(value);
                })
                .catch(() => {
                    this.common.showMsg("已取消", "info");
                });
        },
        async connectToZpt(value) {
            let funId = "";
            if (this.currentItem == "28") {
                if (value.LXMC == "图片类") {
                    funId = "2801";
                } else {
                    funId = "2802";
                }
            }
            const result = await this.DockBasicData({
                funId: funId ? funId : this.$route.params.ItemID,
                id: value.ID,
            });
            if (result.IsSuccess) {
                this.getTableData();
                this.common.showMsg("对接成功", "success");
            } else {
                this.common.showMsg("对接失败", "error");
            }
        },
        // 获取表格数据
        async getTableData() {
            // 处理筛选，支持多项同时筛选
            let strwhere = "";
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        strwhere += '"' + item.type + "," + item.value + '",';
                    }
                });
            }

            strwhere = strwhere.slice(0, strwhere.length - 1);
            strwhere = "[" + strwhere + "]";
            this.tableGroupAttributes.snTable.table.data = [];
            this.tableGroupAttributes.snTable.table.loading = true;
            let result = await this.getBasicList({
                userid: localStorage.userId,
                ycdid: this.heritageId,
                itemId: this.currentItem,
                authority: false,
                keyWord: this.keyword,
                strwhere: strwhere,
                pageIndex: this.tableGroupAttributes.snPage.currentPage,
                pageSize: this.tableGroupAttributes.snPage.tiaoshu,
                sort: this.sort,
                order: this.order,
            });
            if (result.IsSuccess) {
                if (this.currentItem === "24") {
                    result.ResultValue.gridData.forEach((item) => {
                        let now = this.common.getTimeYmd();
                        if (item.JGYSRQ && item.JGYSRQ <= now) {
                            item.bhgcjzqk = "已验收";
                        } else if (item.JGSJ && item.JGSJ <= now) {
                            item.bhgcjzqk = "竣工待验收";
                        } else if (item.KGSJ && item.KGSJ <= now) {
                            item.bhgcjzqk = "施工中";
                        } else if (
                            !item.KGSJ ||
                            (item.KGSJ && item.KGSJ > now)
                        ) {
                            item.bhgcjzqk = "未开工";
                        } else {
                            item.bhgcjzqk = "";
                        }
                    });
                }
                if (this.currentItem === "5") {
                    result.ResultValue.gridData.forEach((item) => {
                        item.NF = item.NF.slice(0, 10);
                    });
                }
                // 学术研究
                if (this.currentItem === "34") {
                    result.ResultValue.gridData.forEach((item) => {
                        item.CJSJ = item.CJSJ.split(" ")[0];
                    });
                }
                let da = result.ResultValue;
                this.tableGroupAttributes.snTable.table.data = this.dealData(
                    da.gridData
                );
                this.tableGroupAttributes.snPage.count = Number(da.total);
                this.tableGroupAttributes.loading = false;
                this.getRightTable(
                    this.tableGroupAttributes.snTable.table.data[0]
                );
            }
            let da = result.ResultValue;
            let data = da.gridData || [];
            let total = Number(da.total) || 0;
            this.tableGroupAttributes.snTable.table.data = this.dealData(data);
            this.tableGroupAttributes.snPage.count = total;
            this.tableGroupAttributes.loading = false;
            this.getRightTable(this.tableGroupAttributes.snTable.table.data[0]);
        },
        // 部分项数据展示需特殊处理
        dealData(gridData) {
            switch (this.currentItem) {
                case "3":
                    gridData.forEach((row) => {
                        // eslint-disable-next-line
                        row.LRBZ = row.LRBZ.replace(/\#/g, "");
                    });
                    return gridData;
                default:
                    return gridData;
            }
        },
        // 展开子节点(展开再去调服务)
        getRowChildData(value) {
            console.log(value);
        },
        setID() {
            return (
                (Math.random() * 10000000).toString(16).substr(0, 4) +
                "-" +
                new Date().getTime() +
                "-" +
                Math.random().toString().substr(2, 5)
            );
        },
        // 整理右侧表格结构
        getRightTable(data) {
            if (!data) return false;
            if (this.currentItem === "24" && data.LBMC === "工程项目") {
                this.rightTableData.snTable.table.data = [];
                let newData = JSON.parse(JSON.stringify(data));
                let tabledata = newData.PIC;
                let IDs = [];
                this.rightTableData.snTable.table.data = [];
                this.$refs.tableGroup.$refs.snTable.$refs.commonTable.setCurrentRow(
                    data
                ); // 选中当前行
                this.currentRow = data;
                tabledata.forEach((item) => {
                    item.ID = this.setID();
                    IDs.push(item.ID);
                    item.Children = item.PIC || [];
                    item.SHZT = item.shzt;
                    delete item.PIC;
                    item.Children &&
                        item.Children.forEach((ele) => {
                            ele.ID = this.setID();
                            ele.SHZT = ele.shzt;
                            if (ele.name === "其他") {
                                ele.wdmc =
                                    ele.sfyqtfile === "1"
                                        ? ele.PIC.length === 0
                                            ? "待上传"
                                            : "查看"
                                        : "无";
                                ele.shzt = ele.wdmc === "无" ? "" : ele.shzt;
                            } else {
                                ele.wdmc =
                                    ele.PIC.length === 0 ? "待上传" : "查看";
                            }
                        });
                });
                this.rightTableData.snTable.table.data = tabledata;
                this.rightTableData.snTable.table.expandRowKeys = IDs;
            }
        },
        getFileTable(data) {
            if (data.wdmc === "查看") {
                this.fileData.table.data = data.PIC;
                this.dialogData.dialog = true;
            }
        },
        // 跳转到新建或编辑页面
        toNewPage(val, type, updataVersion, addChild, isFold, model) {
            // type: 0 => 新建 1 => 编辑 2 => 查看
            let bm = this.$route.params.ItemID;
            let ID = val.ID;
            let version = val.BBH;
            let fid = val.ID;
            let editPage = this.$route.params.Route;
            let ytID =
                bm === "28" ? (val.LXMC === "图片类" ? "2801" : "2802") : "";
            if (editPage) {
                this.$router.push({
                    name: editPage,
                    query: {
                        type: type,
                        id: addChild ? "" : ID,
                        // LB: this.$route.params.LB,
                        version: updataVersion ? version : "",
                        fid: addChild ? fid : "",
                        ItemID: bm,
                        basicType: this.$route.params.basicType,
                        isFold: isFold,
                        ytID: ytID,
                        RWID: val.RWID || null,
                    },
                });
            }
        },
        // 删除前确认
        deleteData(value, itemID) {
            this.$confirm("与之相关的数据会受到影响,是否删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.delData(value, itemID);
                })
                .catch(() => {
                    this.common.showMsg("已取消删除", "info");
                });
        },
        // 删除数据
        async delData(id, itemID) {
            let res = await this.deleteBasicData({
                id: id,
                itemid:
                    Number(this.currentItem) === 28 && itemID
                        ? itemID === "图片类"
                            ? "2801"
                            : "2802"
                        : this.currentItem,
            });
            if (res.IsSuccess) {
                this.common.showMsg("删除成功", "success");
                this.getTableData();
            } else {
                this.common.showMsg("删除失败", "error");
            }
        },
        // 批量删除
        Deletesome() {
            let checkboxlist = [];
            this.multipleSelection.forEach(function (o) {
                checkboxlist.push(o);
            });
            if (checkboxlist.length === 0) {
                this.common.showMsg("请选择数据项！", "warning");
            } else {
                checkboxlist = checkboxlist.join();
                this.deleteData(checkboxlist);
            }
        },
        // 点击选择框
        selectData(value) {
            this.multipleSelection = [];
            if (value && value.length > 0) {
                value.forEach((item) => {
                    this.multipleSelection.push(item.ID);
                });
            }
        },
        // 表格内容升降序排列
        sortableData(value) {
            this.order = value.order
                ? value.order.slice(0, value.order.length - 6)
                : null;
            this.sort = value.order ? value.prop : null;
            this.getTableData();
        },
        // 点击附件图标的操作
        getFileList(value) {
            let list = value[0].PIC;
            let num = list.length;
            if (num === 1) {
                // 直接点开或者下载
                this.openFile(list);
            } else {
                this.fileData.mp3List = [];
                this.fileData.mp4List = [];
                this.fileData.imgList = [];
                this.fileData.othersPart.list = [];
                list.map((item) => {
                    let dw = item.CCLJ.substring(
                        item.CCLJ.lastIndexOf(".") + 1
                    );
                    switch (dw) {
                        case "mp3":
                            this.fileData.mp3List.push(item.CCLJ);
                            break;
                        case "mp4":
                            this.fileData.mp4List.push(item.CCLJ);
                            break;
                        case "jpg":
                        case "png":
                        case "jpeg":
                        case "gif":
                        case "tiff":
                            this.fileData.imgList.push(item.CCLJ);
                            break;
                        default:
                            this.fileData.othersPart.list.push(item);
                            break;
                    }
                });
                this.fileData.table.data = list;
                this.dialogData.dialog = true;
            }
        },
        // 下载附件
        openFile(value) {
            window.open(value[0].CCLJ);
        },
        // 导出
        exportData() {
            let obj = {
                url: window.REQUEST_URL + "GatherPart/ExportBasicData",
                name: this.currentMC + ".xls",
                para: {
                    itemId: this.currentItem,
                },
            };
            this.common.exportFile(obj);
        },
        // 改变左侧项
        async changeModule(params) {
            this.$router.push({
                name: "basicTable",
                params: params,
            });
            Object.assign(this.$data, this.$options.data());
            this.currentItem = params.ItemID;
            this.currentMC = params.MC;
            const moduleData = await import(
                `./table_config/table_group/sn-table-group-${this.currentItem}`
            );

            let comObj = { ...this.commonData };
            let copyObj = JSON.parse(JSON.stringify(moduleData.default));
            let newObj = { ...this.commonData, ...copyObj };
            this.handleDataAll(newObj, comObj);
            // 统一设置数据状态的枚举及样式
            let columnsData = newObj.snTable.columns.columnsData;
            columnsData.push(
                {
                    prop: "SHZT",
                    label: "数据状态",
                    transList: {
                        0: "未审核", // 未审核 / 提交未审核
                        1: "未提交", // 未提交
                        2: "审核通过", // 审核通过
                        3: "审核不通过", // 审核不通过
                    },
                    colorList: {
                        0: "#28ccd9",
                        1: "#ff9066",
                        2: "#2ec392",
                        3: "#fa6f1b",
                    },
                    minWidth: 120,
                    tip: true,
                },
                {
                    prop: "SCSJ",
                    label: "上传时间",
                    minWidth: 150,
                    sortable: "custom",
                }
            );
            if (this.currentItem === "21") {
                columnsData[columnsData.length - 1].prop = "WDSCSJ";
            }
            if (this.currentItem === "24") {
                this.boxHeight = $(".tableRight").height();
            }
            if (!connectZptNo.includes(this.currentItem)) {
                columnsData.push({
                    showMethod: "showConnect",
                    prop: "SFYDJ",
                    label: "对接状态",
                    transList: {
                        null: "未对接",
                        "": "未对接",
                        0: "未对接",
                        1: "已对接",
                    },
                    colorList: {
                        null: "#28ccd9",
                        "": "#28ccd9",
                        0: "#28ccd9",
                        1: "#2ec392",
                    },
                    width: 100,
                });
            }
            this.tableGroupAttributes = newObj;

            // 将页码信息 放入到 localStorage
            if (localStorage.snPage) {
                // 保存完 数据 返回 赋值
                let snPage = JSON.parse(localStorage.snPage);
                this.tableGroupAttributes.snPage.currentPage =
                    snPage.currentPage;
                this.tableGroupAttributes.snPage.tiaoshu = snPage.tiaoshu;
                this.tableGroupAttributes.snPage.count = snPage.count;
            } else {
                this.tableGroupAttributes.snPage.tiaoshu = 20;
                this.tableGroupAttributes.snPage.currentPage = 1;
            }

            //搜索部分参数 返回页面 重新赋值
            if (localStorage.snSearch) {
                let snSearch = JSON.parse(localStorage.snSearch);
                // 关键字
                if (snSearch.inputData && snSearch.inputData[0].default) {
                    // 样式
                    this.tableGroupAttributes.snSearch.inputData[0].default =
                        snSearch.inputData[0].default;
                    // 请求参数
                    this.keyword = snSearch.inputData[0].default;
                }
                // select 下拉框
                snSearch.selectData.map((item) => {
                    if (item.default) {
                        // 样式
                        this.tableGroupAttributes.snSearch.selectData.map(
                            (item1) => {
                                if (item1.value === item.value) {
                                    item1.default = item.default;
                                }
                            }
                        );
                        // 请求参数
                        this.searchData.map((item1) => {
                            if (item1.type === item.value) {
                                item1.value = item.default;
                            }
                        });
                    }
                });
            }
            this.getTableData();
        },
        // 公共的属性可以不写，提取到common中再赋值到各自的组件中
        handleDataAll(self, common) {
            Object.keys(common).forEach((key) => {
                // eslint-disable-next-line
                if (self.hasOwnProperty(key)) {
                    if (typeof common[key] !== "object") return false;
                    if (
                        key === "selectData" &&
                        Array.isArray(common[key]) &&
                        common[key].length > 0
                    ) {
                        let hash = {};
                        self[key] = [...self[key], ...common[key]];
                        let newArr = self[key].reduce((item, next) => {
                            // eslint-disable-next-line
                            hash[next.label]
                                ? ""
                                : (hash[next.label] = true && item.push(next));
                            return item;
                        }, []);
                        self[key] = newArr;
                        this.searchData = [];
                        self[key].forEach((item) => {
                            let type = item.value;
                            this.searchData.push({ type: type, value: "" });
                        });
                    } else {
                        this.handleDataAll(self[key], common[key]);
                    }
                } else {
                    self[key] = common[key];
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.mainbox {
    width: 100%;
    height: 100%;
    position: relative;

    .tableBox {
        width: 100%;
        height: calc(100% - 42px);
        position: absolute;
        top: 42px;
        left: 0;
    }
}
.table.sn_tablebody {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}
</style>
